<template>
  <footer class="footer is-align-content-end">
    <div class="container">
      <div class="content is-primary has-text-centered">
        <strong style="color: #fff">
          Copyright {{ new Date().getFullYear() }}
        </strong>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
